enum Bases {
  LOCAL = 'local',
  HOMOLOG = 'homolog',
  PROD = 'prod'
};

let BASE: Bases = Bases.LOCAL;

const currentDomain = window.location.href;

if(currentDomain.includes('devaribox.co')) {
  BASE = Bases.HOMOLOG;
} else if(currentDomain.includes('devari.com')) {
  BASE = Bases.PROD;
} else {
  BASE = Bases.LOCAL;
}

let CURRENT_STORE = 'local';

if(currentDomain.includes('reconecta')) {
  CURRENT_STORE = 'reconecta';
}

export { BASE, CURRENT_STORE };